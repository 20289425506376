import i18n from "@/i18n/i18n";

export const Subject =  [
  {
    id: 1,
    value: i18n.t('contact.form.subject.placeholder.1')
  },
  {
    id: 2,
    value: i18n.t('contact.form.subject.placeholder.2')
  },
  {
    id: 3,
    value: i18n.t('contact.form.subject.placeholder.3')
  },
  {
    id: 4,
    value: i18n.t('contact.form.subject.placeholder.4')
  }
];
