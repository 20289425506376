<template>
  <HomeLayout content-classname="px-4 md:px-16">
    <template #header>
      <div class="test h-screen w-full text-left flex flex-col justify-center">
        <div class="h-full flex items-center z-10">
          <h1 class="geminis text-7xl lg:text-8xl 2xl:text-9xl text-shadow ml-6 md:ml-20"> {{ $t('contact.main_title.1') }} <br> {{ $t('contact.main_title.2') }} <br> {{ $t('contact.main_title.3') }} </h1>
        </div>
      </div>
    </template>
    <template #content>
      <div class="grid">
        <div class="space-y-10 grid">
          <div class="w-full text-center mt-2 space-y-1">
            <h1 class="text-space-green geminis text-4xl">{{ $t('contact.title') }}</h1>
            <p class="font-bold">
              {{ $t('contact.text.1') }}
            </p>
            <span class="text-center">
              {{ $t('contact.text.2') }}
            </span>
          </div>
          <div class="flex justify-center">
            <div class="bg-white w-full lg:w-3/5 xl:w-2/5 flex flex-col items-center rounded-lg p-6">
              <div class="w-full space-y-4 text-black">
                <div class="space-y-2">
                  <p class="font-bold md:text-xl">{{ $t('contact.form.who.label') }}</p>
                  <TextFormInput
                    type="text"
                    :placeholder="$t('contact.form.who.placeholder.1')"
                    :value="formData.lastname"
                    :errors="errors"
                    field="lastname"
                    @input="onFieldChanged('lastname', $event)"
                  />
                  <TextFormInput
                    type="text"
                    :placeholder="$t('contact.form.who.placeholder.2')"
                    :value="formData.firstname"
                    :errors="errors"
                    field="firstname"
                    @input="onFieldChanged('firstname', $event)"
                  />
                </div>
                <div class="space-y-2">
                  <p class="font-bold md:text-xl">{{ $t('contact.form.mail.label') }}</p>
                  <TextFormInput
                    type="text"
                    :placeholder="$t('contact.form.mail.placeholder')"
                    :value="formData.email"
                    :errors="errors"
                    field="email"
                    @input="onFieldChanged('email', $event)"
                  />
                </div>
                <div class="space-y-2">
                  <p class="font-bold md:text-xl">{{ $t('contact.form.subject.label') }}</p>
                  <SelectFormInput
                    :items="subject"
                    :placeholder="$t('contact.form.subject.placeholder.default')"
                    :value="formData.subject"
                    :errors="errors"
                    field="subject"
                    @input="onFieldChanged('subject', $event)"
                  />
                </div>
                <div class="space-y-2">
                  <p class="font-bold md:text-xl">{{ $t('contact.form.messages.label') }}</p>
                  <TextAreaFormInput
                    :value="formData.message"
                    :placeholder="$t('contact.form.messages.placeholder')"
                    :errors="errors"
                    field="message"
                    @input="onFieldChanged('message', $event)"
                  />
                </div>
              </div>
              <PrimaryButton
                bg-color="bg-space-dark-blue"
                text="Envoyer"
                classes="w-full lg:w-2/3 mt-8"
                @click="onSend"
              />
            </div>
          </div>
          <div v-if="successMessage" class="flex justify-center">
            <div class="bg-white w-2/5 flex flex-col items-center rounded-lg p-6 text-green-600 font-bold">
              <p>
                {{ successMessage }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="w-full flex flex-col items-center justify-center mt-10">
        <img
          :data-src="require('@/assets/images/dotcode.png')"
          alt="Dotcode"
          class="z-10 w-1/2 md:w-auto"
        >
        <div class="geminis">
          Made with love by l'équipe 12
        </div>
        <div class="geminis">
          2022, Dotcode
        </div>
      </div>
    </template>
  </HomeLayout>
</template>

<script>
import HomeLayout from "@/layouts/HomeLayout";
import TextFormInput from "@/components/inputs/TextFormInput";
import SelectFormInput from "@/components/inputs/SelectFormInput";
import TextAreaFormInput from "@/components/inputs/TextAreaFormInput";
import PrimaryButton from '@/components/buttons/PrimaryButton';
import { Subject } from '@/constants/edito/contactConstant';
import FormService from "@/services/formService";
import UserService from "@/services/userService";

export default {
  name: 'Contact',
  components: {
    PrimaryButton,
    TextAreaFormInput,
    SelectFormInput,
    TextFormInput,
    HomeLayout
  },
  data () {
    return {
      formData : {
        firstname: '',
        lastname: '',
        email: '',
        subject: '',
        message: ''
      },
      subject: Subject,
      errors: {},
      successMessage: null
    };
  },
  async mounted() {
    await this.$recaptchaLoaded();
    this.$recaptchaInstance.showBadge();
  },
  destroyed() {
    this.$recaptchaInstance.hideBadge();
  },
  methods: {
    async onSend() {
      const formErrors = {
        firstname: FormService.checkNotEmpty(this.formData.firstname),
        lastname: FormService.checkNotEmpty(this.formData.lastname),
        email: FormService.hasValidEmailFormat(this.formData.email),
        subject: FormService.checkNotEmpty(this.formData.subject),
        message: FormService.checkNotEmpty(this.formData.message),
      };

      this.errors = {};
      if (FormService.hasErrors(formErrors)){
        this.errors = formErrors;
        return;
      }

      const token = await this.$recaptcha('contact');

      await UserService.sendContactEmail(this.formData, token);
    },
    onFieldChanged(field, value){
      this.formData[field] = value;
      this.errors[field] = undefined;
    },
  }
};
</script>
<style scoped>
.test {
  background: url('~@/assets/images/SpaceCode_contactbg-01.svg') no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
